import React from 'react';
import img from '../images/web.svg'; 
import img2 from '../images/app.svg';
import img3 from '../images/hosting.svg';
import img4 from '../images/consultation.svg';
import Record from '../images/Record.webp'; // adjust the path if needed
import ai from '../images/ai.webp'; // adjust the path if needed
import invoice from '../images/invoice.webp'; // adjust the path if needed   
import marketplace from '../images/marketplace.webp'; // adjust the path if needed   

const Services = () => {

    return (
        <div id="services" className="bg-gray-100 py-12" >
            <section data-aos="zoom-in-down">
                    <div className="my-4 py-4">
                        <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">services</h2>
                        
                        <div className='flex justify-center'>
                            <div className='w-24 border-b-4 border-blue-900'></div>
                        </div>
                        <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-blue-900">A comprehensive solution package tailored to meet all your needs</h2>
                    </div>

                    <div className="px-12" data-aos="fade-down" data-aos-delay="600">
                        <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-5">
                            
                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={Record} />
                                    <h2 className="font-semibold my-4 text-2xl text-center">Record Incoming Calls</h2>
                                    <p className="text-md font-medium">
                                    We capture and store incoming calls in an organized, easy-to-access format, allowing you to manage them efficiently. These recordings can be shared seamlessly among team members, ensuring everyone stays informed and has access to important details
                                    </p>
                                </div>
                            </div>

                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={ai} />
                                    <h2 className="font-semibold my-4 text-2xl text-center">AI Dispatching</h2>
                                    <p className="text-md font-medium">
                                    Our platform leverages cutting-edge AI technology to ensure that the right resources are dispatched to the right location at precisely the right time. By intelligently analyzing job requirements and technician availability, our system optimizes workforce deployment, reducing delays, improving efficiency, and ensuring seamless service delivery.
                                    </p>
                                </div>
                            </div>

                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={invoice} />
                                    <h2 className="font-semibold my-4 text-2xl text-center ">Invoicing</h2>
                                    <p className="text-md font-medium">
                                    We generate professional Invoicing  for your customers, streamlining the billing process. In addition, you can easily create detailed estimates and quotes, allowing you to provide accurate cost breakdowns and proposals before the work begins
                                    </p>
                                </div>
                            </div>    

                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm">
                                    <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={marketplace} />
                                    <h2 className="font-semibold my-4 text-2xl text-center ">Marketplace</h2>
                                    <p className="text-md font-medium">
                                    Purchase only the products you need for upcoming jobs, with exclusive discounts and savings on shipping.
                                    </p>
                                </div>
                            </div>

                                         
                        </div>
                    </div>
            </section>

            
        </div>
    )
}

export default Services;